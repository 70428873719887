import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { connect } from "react-redux";

import Map from "./Map";
import DeviceInfo from "./DeviceInfo";

import "../css/Home.css";

const firebaseConfig = {
    apiKey: "AIzaSyBphjmpGD33BbPIa0HULnnlt23gfgWhNtc",
    authDomain: "crash-detector.firebaseapp.com",
    databaseURL: "https://crash-detector.firebaseio.com",
    projectId: "crash-detector",
    storageBucket: "crash-detector.appspot.com",
    messagingSenderId: "760711940122",
    appId: "1:760711940122:web:20e3707e3d8697eb"
};

firebase.initializeApp(firebaseConfig);

const Home = (props) => {

    console.log("Home Props ", props);

    const { getAccessTokenSilently } = useAuth0();
    const [firestoreDB, setFirestoreDB] = useState(null);

    useEffect(() => {
        console.log("useEffect");
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    audience: "https://api.prod.ino-vibe.ino-on.net/web/",
                });

                let resp = await axios({
                    method: "post",
                    url: "https://rest.ino-vibe.ino-on.dev/rest/v3/auth_firebase",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    },
                });

                firebase
                    .auth()
                    .signInWithCustomToken(resp.data)
                    .then(c => {
                        console.log("Firebase authenticated");
                        let db = firebase.firestore();
                        setFirestoreDB(db);
                    })
                    .catch(e => {
                        console.log("Failed to authenticate firebase");
                    });
            } catch (e) {
                console.log(e);
            }
        })();
    }, [getAccessTokenSilently]);

    const [devices, setDevices] = useState({});

    useEffect(() => {
        console.log(`useEffect for DB ${firestoreDB}`);
        if(firestoreDB == null) {
            return;
        }

        var loadedDevices = {};
        firestoreDB
            .collection("testbed-device")
            .onSnapshot(docs => {
                console.log("OnSnapshot");
                docs.forEach(doc => {
                    let data = doc.data();
                    if(data.install_location == null) {
                        return;
                    }
                    console.log(doc.id, data.install_location.latitude, data.install_location.longitude);
                    data.id = doc.id;
                    loadedDevices[doc.id] = data;
                });
                setDevices(loadedDevices);
            });
    }, [firestoreDB]);

    const { selectDeviceID } = props;

    var selectedDevice = devices[selectDeviceID];

    console.log("Selected ID ", selectDeviceID);
    console.log("Selected ", selectedDevice);

    return (
        <div className={"home_container"}>
            <div className={"home__map"}>
                <Map devices={devices}></Map>
            </div>
            {
                selectDeviceID !== null ? (
                    <div className={"home__dev_info"}>
                        <DeviceInfo device={selectedDevice}></DeviceInfo>
                    </div>
                ) : (
                    <div></div>
                )
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Home);