import React, { useState, useEffect } from "react";
import "firebase/firestore";
import axios from "axios";

import DistanceChart from "./DistanceChart";
import AccelerometerChart from "./AccelerometerChart";

import "../css/DeviceInfo.css";


const DeviceInfo = ({ device }) => {
    console.log("DeviceInfo " + device);

    const [address, setAddress] = useState("--");

    useEffect(() => {
        if(device == null) {
            return;
        }

        console.log("Get address ", device);
        let loc = device.install_location;
        (async () => {
            let baseURL = "https://maps.googleapis.com/maps/api/geocode/json";
            let apiKey = "AIzaSyAr7zYhH3iu81eILYFMBNAM-QBG7XRPYCc";
            axios({
                method: "GET",
                url: `${baseURL}?latlng=${loc.latitude},${loc.longitude}&key=${apiKey}&language=ko`,
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            })
            .then(resp => {
                const addr = resp.data.results[0].formatted_address;
                console.log("Address ", resp, addr);
                setAddress(addr);
            })
            .catch(e => {
                console.log("Address ", e);
            });
        })();

    }, [device]);

    const voltageToPercent = (mv, temperature) => {
        const minMV = 3300;
        const maxMV = 0.0125*Math.pow(temperature, 3) - 0.512*Math.pow(temperature, 2) + 11.8*temperature + 3754;

        var percent = (mv - minMV) / (maxMV - minMV) * 100;
        if(percent > 100) {
            percent = 100.0;
        }
        return percent.toFixed(0);
    }

    return (
        <div className={"device_info_container"}>
            <div className={"device_info__basic"}>
                <div className={"device_info__basic__title"}>{device ? device.id : "--"}</div>
                <div className={"device_info__basic__entry"}>
                    <div>이름</div>
                    <div>{device ? device.alias : "--"}</div>
                </div>
                <div className={"device_info__basic__entry"}>
                    <div>설치자</div>
                    <div>{device ? device.installer: "--"}</div>
                </div>
                <div className={"device_info__basic__entry"}>
                    <div>설치일</div>
                    <div>{device ? device.time_installed.toDate().toLocaleString() : "--"}</div>
                </div>
                <div className={"device_info__basic__entry"}>
                    <div>최종 업데이트</div>
                    <div>{device ? device.time_updated.toDate().toLocaleString() : "--"}</div>
                </div>

                <div className={"device_info__basic__entry"}>
                    <div>온도</div>
                    <div>{device ? device.temperature.toFixed(1) : "--"}℃</div>
                </div>

                <div className={"device_info__basic__entry"}>
                    <div>습도</div>
                    <div>{device ? device.humid.toFixed(0) : "--"}%</div>
                </div>

                <div className={"device_info__basic__entry"}>
                    <div>배터리</div>
                    <div>{device ? voltageToPercent(device.battery_mv, device.temperature) : "--"}%({device ? device.battery_mv : "--"}mV)</div>
                </div>

                <div className={"device_info__basic__entry"}>
                    <div>주소</div>
                    <div>{address}</div>
                </div>
            </div>
            <div className={"device_info__tab"}>
                {
                    device.mode_displacement === true ? (
                        <Tab>
                            <DistanceChart device={device}>변위</DistanceChart>
                            <AccelerometerChart device={device}>가속도</AccelerometerChart>
                        </Tab>
                    ) : (
                        <Tab>
                            <AccelerometerChart device={device}>가속도</AccelerometerChart>
                        </Tab>
                    )
                }
            </div>
        </div>
    );
}

const Tab = (props) => {
    console.log("Tab ", props, Array.isArray(props.children));

    var children = [];
    if(Array.isArray(props.children)) {
        props.children.forEach(c => {
            console.log(c);
            children.push(c);
        })
    } else {
        children.push(props.children);
    }
    const [selectIndex, setSelectIndex] = useState(0);

    return (
        <div className={"tab_container"}>
            <div className={"tab_container__header"}>
            {
                children.map((c, i) => {
                    console.log("Item " + c.props.children, i);
                    return (
                        <div
                            key={i}
                            className={selectIndex === i ? "tab_container__item--select" : "tab_container__item"}
                            onClick={() => {
                                console.log(`Click item ${i}`);
                                setSelectIndex(i);
                            }}>
                            <p>{c.props.children}</p>
                        </div>
                    );
                })
            }
            </div>
            <div className={"tab_container__content"}>
                {children[selectIndex]}
            </div>
        </div>
    );
};

export default DeviceInfo;