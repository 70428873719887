import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import firebase from "firebase/app";
import "firebase/firestore";

import "../css/DistanceChart.css";


const DistanceChart = ({ device }) => {
    var deviceID = device == null ? "" : device.id;
    console.log(deviceID);

    const [x, setX] = useState([]);
    const [y, setY] = useState([]);
    const [z, setZ] = useState([]);

    // Load image from Firestore.
    // TODO: Configurable range of query date required.
    useEffect(() => {
        console.log("DistanceChart.useEffect");
        firebase.firestore()
            .collection(`testbed-device/${deviceID}/image`)
            .orderBy("time_created", "desc")
            .get()
            .then(q => {
                console.log("DistanceChart query");

                var xChartData = [];
                xChartData.push([{ type: "date", label: "D" }, "X", "Temperature"]);

                var yChartData = [];
                yChartData.push([{ type: "date", label: "D" }, "Y", "Temperature"]);

                var zChartData = [];
                zChartData.push([{ type: "date", label: "D" }, "Z", "Temperature"]);

                q.forEach(doc => {
                    var x, y, z;

                    const docData = doc.data();
                    if (docData.x != null) {
                        x = docData.x;
                        xChartData.push([docData.time_created.toDate(), x, docData.temperature]);
                    }
                    if (docData.y != null) {
                        y = docData.y;
                        yChartData.push([docData.time_created.toDate(), y, docData.temperature]);
                    }
                    if (docData.z != null) {
                        z = docData.z;
                        zChartData.push([docData.time_created.toDate(), z, docData.temperature]);
                    }

                });

                setX(xChartData);
                setY(yChartData);
                setZ(zChartData);
            })
            .catch(e => {
                console.log("e");
            });
    }, [deviceID]);

    const chartOptions = {
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5, targetAxisIndex: 0, title: "mm" },
            1: { color: "orange", lineWidth: 1.5, targetAxisIndex: 1 },
        },
        vAxes: {
            0: {
                title: "mm",
                titleTextStyle: { color: "#FFFFFF" },
                textStyle: { color: "#FFFFFF" },
                gridLines: { color: "#676767" },
                minorGridlines: { color: "#37404c" },
            },
            1: {
                title: "Temperature",
                titleTextStyle: { color: "#FFFFFF" },
                textStyle: { color: "#FFFFFF" },
                gridLines: { color: "none" },
                minorGridlines: { color: "none" },
                viewWindow: { min: -20, max: 50 },
            }
        },
        hAxis: {
            title: "Time",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: {color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        chartArea: {
            width: "80%",
            height: "75%",
        },
        legend: { position: "top", textStyle: { color: "white" }},
        pointSize: 2,
    };

    return (
        <div className={"distance_container"}>
            <div className={"distance__chart"}>
                <p>X축 거리</p>
                {
                    x.length > 1 ? (
                        <Chart
                            chartType={"LineChart"}
                            loader={<div>Loading</div>}
                            options={chartOptions}
                            data={x} />
                    ) : (
                        <div>변위 데이터가 없습니다.</div>
                    )
                }
            </div>

            <div className={"distance__chart"}>
                <p>Y축 거리</p>
                {
                    y.length > 1 ? (
                        <Chart
                            chartType={"LineChart"}
                            loader={<div>Loading</div>}
                            options={chartOptions}
                            data={y} />
                    ) : (
                        <div>변위 데이터가 없습니다.</div>
                    )
                }
            </div>

            <div className={"distance__chart"}>
                <p>Z축 거리</p>
                {
                    z.length > 1 ? (
                        <Chart
                            chartType={"LineChart"}
                            loader={<div>Loading</div>}
                            options={chartOptions}
                            data={z} />
                    ) : (
                        <div>변위 데이터가 없습니다.</div>
                    )
                }
            </div>
        </div>
    );

}

export default DistanceChart;