import React, { useState } from "react";
import firebase from "firebase/app";
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from "react-naver-maps";
import { connect } from "react-redux";
import { EVENT_SELECT_DEVICE } from "../store/reducer";
import deviceMarker from "../assets/icon_device_marker.png"
import deviceMarkerActive from "../assets/icon_device_marker_active.png"
import "../css/Map.css";


const Map = ({devices, selectDeviceID, dispatchSelectDevice}) => {

    console.log("Map ", selectDeviceID);

    const [draggable, setDraggable] = useState(false);

    return (
        <RenderAfterNavermapsLoaded ncpClientId={"m7xrg8bim3"} loading={<p>Map is loading...</p>}>
            <NaverMap
                className={"map_container"}
                style={{ width: "100%", height: "100%" }}
                onClick={() => {
                    console.log("Click Map");
                    dispatchSelectDevice({ type: EVENT_SELECT_DEVICE, devid: null });
                }}>
                {
                    Object.keys(devices).map(k => {
                        var markerIcon = null;
                        if(selectDeviceID != null && selectDeviceID === k) {
                            markerIcon = deviceMarkerActive;
                        } else {
                            markerIcon = deviceMarker;
                        }

                        return (<Marker
                            key={k}
                            icon={markerIcon}
                            position={{ lat: devices[k].install_location.latitude, lng: devices[k].install_location.longitude }}
                            draggable={draggable}
                            onClick={() => {
                                dispatchSelectDevice({type: EVENT_SELECT_DEVICE, devid: k});
                            }}
                            onDblclick={() => {
                                setDraggable(!draggable);
                            }}
                            onPositionChanged={(e) => {
                                if(devices[k].install_location.latitude !== e.lat() && devices[k].install_location.longitude !== e.lng()) {
                                    console.log("Update location");
                                    firebase.firestore()
                                        .doc(`testbed-device/${k}`)
                                        .update({"install_location": new firebase.firestore.GeoPoint(e.lat(), e.lng())})
                                        .then((d) => {
                                            console.log(`Write Success ${d}`);
                                        })
                                        .catch(e => {
                                            console.log(`Write error ${e}`)
                                        })
                                }
                            }}>
                        </Marker>);
                    })
                }
            </NaverMap>
        </RenderAfterNavermapsLoaded>
    );

}

export default connect(
    state => ({ selectDeviceID: state.selectDeviceID }),
    dispatch => ({ dispatchSelectDevice: msg => dispatch(msg) })
)(Map);