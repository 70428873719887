import React from 'react';
import { Router, Route, Switch } from "react-router-dom";
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Header from "./component/Header";
import history from "./utils/history";

import Home from "./component/Home";
import LoadingRotete from "./component/LoadingRotate";

import './App.css';

const App = () => {
  const { isAuthenticated, isLoading, error } = useAuth0();

  console.log("App ", isAuthenticated, isLoading);

  if (error) {
    console.log(error.message);
  }

  if (isLoading) {
    return (
      <div style={{position: "absolute", top: "50%", left: "50%"}}>
        <LoadingRotete 
          width={"40px"}
          height={"40px"}
          spinnerColor={"#161b20"}
          spinnerWidth={5}/>
      </div>
    );
  }

  return (
    <Router history={history}>
      <div className={"app_container"}>
        <Header />
        <Switch>
          <Route path="/" exact component={withAuthenticationRequired(Home)}></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;