import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import firebase from "firebase/app";
import "firebase/firestore";

import "../css/AccelerometerChart.css";

const itemsPerPage = 10;

const accItems = (deviceID, limit) => {
    var q = firebase.firestore()
        .collection(`testbed-device/${deviceID}/acc`)
        .orderBy("time_created", "desc");
    if(limit != null) {
        q = q.limit(itemsPerPage);
    }
    return q;
}

const tensions = (deviceID) => {
    return firebase.firestore()
        .collection(`testbed-device/${deviceID}/tension`)
        .orderBy("time_created", "asc");
}

const NoItem = () => {
    return (
        <div style={{
            fontSize: "14px",
            textAlign: "center",
            margin: "20px 0px 20px 0px",
        }}>
            가속도 데이터가 없습니다.
        </div>
    );
};

const TensionChart = ({ deviceID }) => {

    const [tension, setTension] = useState([[{ type: "date", label: "T"}, "T"], [0, 0]]);

    useEffect(() => {
        console.log("TensionChart.useEffect");
        tensions(deviceID)
            .get()
            .then(docs => {
                console.log("Get tensions Success");

                var t = [];
                t.push([{ type: "date", label: "T"}, "T"])
                docs.forEach(doc => {
                    var d = doc.data();
                    console.log(d);
                    t.push([d.time_created.toDate(), d.tension]);
                });

                setTension(t);
            })
            .catch(e => {
                console.log("Get tensions Failed");
            });
    }, [deviceID]);

    const chartOptions = {
        vAxis: {
            title: "T",
            titleTextStyle: { color: "#FFFFFF" },
            textStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        hAxis: {
            title: "Time",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "80%",
            height: "75%",
        },
        legend: { position: "none" },
        pointSize: 2,
    };

    return (
        <div className={"acc__tension"}>
            <p>장력</p>
            <Chart
                chartType={"LineChart"}
                loader={<div>Loading</div>}
                options={chartOptions}
                data={tension} />
        </div>
    );

}

const AccList = ({ docList }) => {

    const [selectDocID, setSelectDocID] = useState(null);

    const accChartOptions = {
        vAxis: {
            title: "mG",
            titleTextStyle: { color: "#FFFFFF" },
            textStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        hAxis: {
            title: "Second",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "70%",
            height: "70%",
        },
        legend: { position: "none" },
    };

    const psdChartOptions = {
        vAxis: {
            title: "psd",
            titleTextStyle: { color: "#FFFFFF" },
            textStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        hAxis: {
            title: "Frequency",
            titleTextStyle: { color: "#FFFFFF" },
            gridLines: { color: "#676767" },
            minorGridlines: { color: "#37404c" },
        },
        backgroundColor: { fill: "transparent" },
        series: {
            0: { color: "#48cdd8", lineWidth: 1.5 },
        },
        chartArea: {
            width: "70%",
            height: "70%",
        },
        legend: { position: "none" },
    };

    return (<div className={"acc__lists"}>
        {
            docList.map(doc => {
                const data = doc.data;
                var x_psd = [["f", "x"]];
                var y_psd = [["f", "y"]];
                var z_psd = [["f", "z"]];

                if(data.psd_f != null) {
                    data.psd_f.forEach((f, i) => {
                        x_psd.push([f, data.psd_x[i]]);
                        y_psd.push([f, data.psd_y[i]]);
                        z_psd.push([f, data.psd_z[i]]);
                    });
                }

                var x_acc = [["t", "x"]];
                var y_acc = [["t", "y"]];
                var z_acc = [["t", "z"]];

                var time_tick = 1.0 / data.sample_rate;

                if(data.x != null && data.y != null && data.z != null) {
                    var acc_len = data.x.length;
                    for (var i = 0; i < acc_len; i++) {
                        x_acc.push([time_tick * i, data.x[i]]);
                        y_acc.push([time_tick * i, data.y[i]]);
                        z_acc.push([time_tick * i, data.z[i]]);
                    }
                }

                return (
                    <div className={"acc__entity"} key={doc.id}>
                        <div
                            className={doc.id === selectDocID ? "acc__entity__title--select" : "acc__entity__title"}
                            onClick={() => {
                                if (doc.id === selectDocID) {
                                    console.log("Clear selection");
                                    setSelectDocID(null);
                                } else {
                                    console.log("Select acc doc ", doc.id);
                                    setSelectDocID(doc.id);
                                }
                            }}>
                            <p className={"acc__entity__title__name"}>{data.time_created.toDate().toLocaleString()}</p>
                        </div>
                        {
                            (doc.id === selectDocID) ? (
                                <div className={"acc__entity__chart_container"}>
                                    <div className={"acc__entity__chart"}>
                                        <p>X축 가속도</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={accChartOptions}
                                                data={x_acc} />
                                        </div>
                                    </div>

                                    <div className={"acc__entity__chart"}>
                                        <p>X축 PSD</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={psdChartOptions}
                                                data={x_psd} />
                                        </div>
                                    </div>

                                    <div className={"acc__entity__chart"}>
                                        <p>Y축 가속도</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={accChartOptions}
                                                data={y_acc} />
                                        </div>
                                    </div>

                                    <div className={"acc__entity__chart"}>
                                        <p>Y축 PSD</p>
                                        <Chart
                                            chartType={"LineChart"}
                                            loader={<div>Loading</div>}
                                            options={psdChartOptions}
                                            data={y_psd} />
                                    </div>

                                    <div className={"acc__entity__chart"}>
                                        <p>Z축 가속도</p>
                                        <div>
                                            <Chart
                                                chartType={"LineChart"}
                                                loader={<div>Loading</div>}
                                                options={accChartOptions}
                                                data={z_acc} />
                                        </div>
                                    </div>

                                    <div className={"acc__entity__chart"}>
                                        <p>Z축 PSD</p>
                                        <Chart
                                            chartType={"LineChart"}
                                            loader={<div>Loading</div>}
                                            options={psdChartOptions}
                                            data={z_psd} />
                                    </div>
                                </div>
                            ) : (
                                    <div></div>
                                )
                        }
                    </div>
                );
            })
        }
    </div>);
}

const AccelerometerChart = ({ device }) => {

    var deviceID = device.id;

    const [docList, setDocList] = useState([]);
    const [refDocFirst, setRefDocFirst] = useState(null);
    const [refDocLast, setRefDocLast] = useState(null);

    const handleAccQuery = (q) => {
        var docs = [];
        var docRefs = [];
        q.forEach(doc => {
            docRefs.push(doc);
            docs.push({ id: doc.id, data: doc.data() });
        });

        setDocList(docs);
        setRefDocFirst(docs.length > 0 ? docRefs[0] : null);
        setRefDocLast(docs.length > 0 ? docRefs[docs.length - 1] : null);
    };

    useEffect(() => {
        console.log(`Acc.useEffect ${deviceID}`);
        accItems(deviceID, itemsPerPage)
            .get()
            .then(q => {
                var docs = [];
                var docRefs = [];
                q.forEach(doc => {
                    docRefs.push(doc);
                    docs.push({ id: doc.id, data: doc.data() });
                });

                setDocList(docs);
                setRefDocFirst(docs.length > 0 ? docRefs[0] : null);
                setRefDocLast(docs.length > 0 ? docRefs[docs.length - 1] : null);
            })
            .catch(e => {
                console.log("Acc ", e);
            })
    }, [deviceID]);

    return (
        <div className={"acc_container"}>
            {
                docList.length > 0 ? (
                    <div>
                        {
                            device.mode_tension === true ? (
                                <TensionChart deviceID={deviceID}></TensionChart>
                            ) : (
                                <div></div>
                            )
                        }
                        <AccList docList={docList} />
                    </div>
                ) : (
                    <NoItem />
                )
            }

            {
                docList.length > 0 ? (
                    <div className={"acc__lists__page"}>
                        <div onClick={() => {
                            accItems(deviceID, itemsPerPage)
                                .endBefore(refDocFirst)
                                .get()
                                .then(q => {
                                    handleAccQuery(q);
                                })
                                .catch(e => {
                                    console.log("Acc ", e);
                                })
                        }}>Prev.</div>

                        <div onClick={() => {
                            accItems(deviceID, itemsPerPage)
                                .startAfter(refDocLast)
                                .get()
                                .then(q => {
                                    handleAccQuery(q);
                                })
                                .catch(e => {
                                    console.log("Acc ", e);
                                })
                        }}>Next</div>
                    </div>
                ) : (
                    <div></div>
                )
            }
        </div>
    );

};

export default AccelerometerChart;