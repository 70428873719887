import React from "react";

import "../css/LoadingRotate.css";


const LoadingRotate = (props) => {
    console.log("Spinner ", props);

    var style = {
        width: props.width,
        height: props.height,
        borderWidth: props.spinnerWidth,
        borderColor: props.spinnerColor,
        borderTopColor: "transparent",
    }

    return (
        <div className={"spinner"} style={style}>
        </div>
    );
};

export default LoadingRotate;