import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import store from "./store/reducer";

import { Provider } from "react-redux";

const onRedirectCallback = (appState) => {
  console.log("onRedirectCallback ", appState);
  history.push(
    appState && appState.returnTo
    ? appState.returnTo : window.location.pathname
  );
}

ReactDOM.render(
    <Auth0Provider
      domain="ino-vibe.auth0.com"
      clientId="obocd2MXEpSSgnLskddaIPgLh6QvA3AZ"
      audience={"https://api.prod.ino-vibe.ino-on.net/web/"}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <Provider store={store}>
        <App />
      </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
