import { createStore } from "redux";

export const EVENT_SELECT_DEVICE = 0;

const initialState = {
  selectDeviceID: null,
  // selectDeviceID: "INOTEST",
};

const rootReducer = (state = initialState, action) => {
  console.log("RootReducer " + action.type);
  if(action.type === EVENT_SELECT_DEVICE) {
    console.log("Reducer select " + action.device);
    return Object.assign({}, state , {
      selectDeviceID: action.devid,
    });
  }
  return state;
};

const store = createStore(rootReducer);

export default store;