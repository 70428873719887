import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import Logo from "../assets/inoon_logo.png";

import "../css/Header.css";


const Header = () => {
    const { user, isAuthenticated } = useAuth0();

    var username = user ? user.name : "--";

    return (
        <div className={"header_container"}>
            <img alt={"logo"} src={Logo}></img>
            <p>{"서울 테스트베드"}</p>
            <div className={"header__spacer"}></div>
            <p>{username}</p>
            <div className={"header__button"}>
                {
                    isAuthenticated ? (
                        <LogoutButton></LogoutButton>
                    ) : (
                        <LoginButton></LoginButton>
                    )
                }
            </div>
        </div>
    );
}

export default Header;